import React, { useEffect } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Alert } from "antd";
import styled from "styled-components";
import { useStore } from "../../hooks/useStore";
import baseRequest from "../../_core/baseRequest";

export const Login = () => {
  const stores = useStore();
  const alertRef = useRef();
  const history = useHistory();
  useEffect(() => {
    if (stores.loginStore.loggedIn) history.push("/");
  });

  const onFinish = async values => {
    const login = await stores.loginStore.loginRequest({ values });

    if (login) {
      const { authenticated } = login.data.data;
      if (authenticated) {
        const { user, sessionToken } = login.data.data;
        stores.loginStore.setUser(user);
        stores.loginStore.setSessionToken(sessionToken);
        baseRequest.addHeader(sessionToken);
        await stores.loginStore.addHeader();
        stores.loginStore.setLoggedIn(true);
        history.push("/");
      }
    } else {
      alertRef.current.style.display = "block";
    }
  };

  return (
    <Container>
      <h1>Login</h1>
      <AlertWrapper ref={alertRef}>
        <Alert message="Login Failed" type="error" />
      </AlertWrapper>

      <Form
        name="basic"
        initialValues={{
          remember: true
        }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item label="Username" name="username">
          <Input autoFocus />
        </Form.Item>

        <Form.Item label="Password" name="password">
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  margin: 20% auto;
  text-align: center;
  @media screen and (max-width: 425px) {
    width: 90%;
  }
`;
const AlertWrapper = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
  display: none;
`;
