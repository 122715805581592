import FileUpload from "./FileUpload";
import Links from "./Links";
import { Divider } from "antd";
const LinksAndFilesTab = ({ values }) => {
  return (
    <div>
      <FileUpload />
      <Divider />
      <Links values={values} />
    </div>
  );
};

export default LinksAndFilesTab;
