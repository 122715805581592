import React, { useEffect, useState } from "react";
import {
  Avatar,
  Row,
  Col,
  Typography,
  Space,
  Tabs,
  Button,
  Form,
  Skeleton,
  Modal
} from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";

import moment from "moment";
import { useFormik } from "formik";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useStore } from "../../hooks/useStore";
import { observer } from "mobx-react-lite";
import config from "../../_core/config";
import * as Yup from "yup";

import Navbar from "../navbar/Navbar";
import BasicInfoTab from "./basicInfoTab/BasicInfoTab";
import Footer from "../footer/Footer";
import EducationTab from "./educationTab/EducationTab";
import ExperienceTab from "./experienceTab/ExperienceTab";
import LanguagesTab from "./languagesTab/LanguagesTab";
import LinksAndFilesTab from "./linksAndFilesTab/LinksAndFilesTab";
import NotesTab from "./NotesTab/NotesTab";

const { Title } = Typography;
const { TabPane } = Tabs;
const initialState = {
  basicInfo: { name: "Yeni", surname: "Kullanıcı" },
  education: [],
  link: [],
  notes: [],
  salaryExpectation: []
};
const validationSchema = Yup.object({
  basicInfo: Yup.object().shape({
    name: Yup.string().required("Zorunlu alan"),
    surname: Yup.string().required("Zorunlu alan"),
    email: Yup.string()
      .email("Geçersiz email")
      .required("Zorunlu alan"),
    phone: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Geçersiz telefon numarası"
      )
      .required("Zorunlu alan"),
    birthDate: Yup.string()
      .nullable(true)
      .test("Date of Birth", "Yaşınız 18'den büyük olmalı.", function(value) {
        return value ? moment().diff(moment(value), "years") >= 18 : true;
      })
  }),
  salaryExpectation: Yup.array()
    .of(
      Yup.object().shape({
        workType: Yup.string().required("Work type required"),
        salary: Yup.number().required("Salary required")
      })
    )
    .compact()
    .min(1, "Zorunlu alan")
    .required("Zorunlu alan")
});
export const Details = observer(props => {
  const stores = useStore();

  const [currentApplicant, setCurrentApplicant] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const baseUrl = config.getBasePublicUrl();
  const [photo, setPhoto] = useState({ url: null, status: false });
  const { handleSubmit, values, errors, setValues } = useFormik({
    initialValues: { ...currentApplicant },
    validationSchema,
    onSubmit: async values => {
      if (stores.applicantStore.photoId) {
        if (stores.applicantStore.photoId === "-1") values.photoId = "";
        else values.photoId = stores.applicantStore.photoId;
      }
      if (stores.applicantStore.fileList) {
        values.fileList = stores.applicantStore.fileList;
      }
      if (values.notes.length > 0) {
        let lastNote = values.notes.reduce((a, b) => {
          if (a.noteCreatedAt.isAfter(b.noteCreatedAt)) return a;
          else return b;
        });
        values.lastNote = {
          date: lastNote.noteCreatedAt.format("DD/MM/YYYY"),
          contact: lastNote.noteCreatedBy
        };
      } else {
        values.lastNote = { date: "N/A", contact: "N/A" };
      }
      values.salaryExpectation = values.salaryExpectation.filter(element => {
        return element;
      });
      await setCurrentApplicant({
        ...values
      });
      if (props.applicantId === "new")
        await stores.applicantStore.createApplicant(values);
      else await stores.applicantStore.editApplicant(values);
      history.push("/");
    }
  });

  const { getById } = stores.applicantStore;
  useEffect(() => {
    async function fetchData() {
      if (props.applicantId !== "new") {
        const result = await getById(props.applicantId);
        setCurrentApplicant({ ...initialState, ...result });
        await setValues({ ...initialState, ...result });
      }
      setLoading(false);
    }
    fetchData();
  }, [props.applicantId, getById, setValues]);

  useEffect(() => {
    if (loading === false) {
      if (currentApplicant.photoId) {
        setPhoto({
          url: baseUrl + "file/" + currentApplicant.photoId,
          status: true
        });
      }
    }
  }, [baseUrl, currentApplicant.photoId, loading]);
  const history = useHistory();
  function showConfirm() {
    Modal.confirm({
      title: "Silmek istediğinizden emin misiniz?",

      content: "Bu başvuran kalıcı olarak silinecektir.",
      okText: "Sil",
      okType: "danger",

      cancelText: "İptal",
      async onOk() {
        await stores.applicantStore.deleteApplicant(currentApplicant._id);
        history.push("/");
      }
    });
  }
  return (
    <div>
      <Navbar homePage={false}></Navbar>
      <Wrapper>
        <Skeleton loading={loading}>
          <Header wrap={false}>
            <Space align="start" wrap={true}>
              <Col>
                <Title level={1}>
                  {currentApplicant?.basicInfo?.name +
                    " " +
                    currentApplicant?.basicInfo?.surname}
                </Title>
              </Col>
              <Col>
                <Title level={3}>
                  {
                    currentApplicant?.techStack?.find(tech => tech.rate === "P")
                      ?.tech
                  }
                </Title>
              </Col>
            </Space>
            <Col flex="1 0 auto">
              <FullHeightRow justify="end" align="middle">
                <Avatar
                  shape="square"
                  size={80}
                  src={photo.status ? photo.url : undefined}
                ></Avatar>
              </FullHeightRow>
            </Col>
          </Header>
          <CustomForm
            layout="vertical"
            onFinish={handleSubmit}
            onValuesChange={(change, all) => {
              setValues({ ...values, ...all });
            }}
          >
            <TopRow justify={"end"}>
              <Form.Item>
                <ActionButton size="large" htmlType="submit">
                  <SaveIcon />
                </ActionButton>
                <ActionButton size="large" onClick={showConfirm}>
                  <DeleteIcon />
                </ActionButton>
              </Form.Item>
            </TopRow>
            {loading ? (
              <></>
            ) : (
              <TabWrap>
                <Tabs id="detailsTabs" defaultActiveKey="0" type="card">
                  <TabPane tab="Temel Bilgiler" key="0">
                    <BasicInfoTab
                      values={values}
                      errors={errors}
                      photoUrl={photo.url}
                    />
                  </TabPane>
                  <TabPane tab="Eğitim" key="1">
                    <EducationTab values={values} />
                  </TabPane>
                  <TabPane tab="Deneyim" key="2">
                    <ExperienceTab values={values} />
                  </TabPane>
                  <TabPane tab="Yabancı Dil" key="3">
                    <LanguagesTab values={values} />
                  </TabPane>
                  <TabPane tab="Linkler&Dosyalar" key="4">
                    <LinksAndFilesTab values={values} />
                  </TabPane>
                  <TabPane tab="Notlar" key="5">
                    <NotesTab values={values} />
                  </TabPane>
                  {/* <TabPane tab="Dosyalar" key="2">
                    <Files></Files>
                  </TabPane>
                  <TabPane tab="Linkler" key="3">
                    <Links values={values}></Links>
                  </TabPane>
                  <TabPane tab="Notlar" key="4">
                    <Notes values={values}></Notes>
                  </TabPane>
                  <TabPane tab="Fotoğraf" key="5">
                    <UploadImage applicantData={currentApplicant}></UploadImage>
                  </TabPane> */}
                </Tabs>
              </TabWrap>
            )}
          </CustomForm>
        </Skeleton>
      </Wrapper>
      <Footer />
    </div>
  );
});

const Wrapper = styled.div`
  padding-top: 90px;
  padding-bottom: 60px;
`;

const SaveIcon = styled(SaveOutlined)`
  font-size: 1.625rem;
`;
const DeleteIcon = styled(DeleteOutlined)`
  font-size: 1.625rem;
`;
const Header = styled(Row)`
  padding: 12px 24px 64px 24px;
`;

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const TopRow = styled(Row)`
  position: sticky;
  right: 0;
  top: 134px;
  z-index: 3;
  padding-right: 24px;
  margin-bottom: -88px;
  .ant-form-item {
    margin-bottom: 9px;
  }
`;

const ActionButton = styled(Button)`
  border: 2px solid rgba(0, 172, 238, 0.5);

  border-radius: 0 0 10px 10px;
  width: 80px;
  color: rgba(0, 172, 238, 0.9);
  background: #e2eefd;
  :first-of-type {
    margin-right: 4px;
  }
`;

const TabWrap = styled.div`
  #detailsTabs > .ant-tabs-content-holder {
    padding-top: 20px;
    > .ant-tabs-content {
      padding: 0 6px;
    }
  }
  /* Select all inputs under details page */
  input,
  button,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker,
  .ant-input-number,
  textarea {
    border-radius: 10px;
  }
  #detailsTabs {
    overflow: unset;
  }
  #detailsTabs > .ant-tabs-nav {
    margin-bottom: 0;
  }
  #detailsTabs > .ant-tabs-content-holder {
    border: 2px solid rgba(0, 172, 238, 0.5);
    border-radius: 0 0 10px 10px;
    border-top: none;
    padding: 48px 16px 16px 16px;
  }

  #detailsTabs
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    background: #e2eefd;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    font-weight: 500;
    :hover {
      background: white;
      border-color: #7fd5f6;
      color: #7fd5f6;
    }
  }
  #detailsTabs
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: black !important;
    }
    border-color: #7fd5f6;

    background: #7fd5f6;
  }

  #detailsTabs > .ant-tabs-nav {
    background: white;
    z-index: 2;
    position: sticky;
    top: 95px;
    border-bottom: 2px solid rgba(0, 172, 238, 0.5);
  }
`;

const CustomForm = styled(Form)`
  legend {
    border-bottom: none;
    margin-bottom: unset;
  }
`;
