import { autorun, toJS } from "mobx";
const myStorage = window.localStorage;

export function autoSave(store, storeName) {
  autorun(() => {
    const temp = toJS(store);
    const data = JSON.stringify({
      sessionToken: temp.sessionToken,
      user: temp.user,
      loggedIn: temp.loggedIn
    });
    myStorage.setItem(storeName, data);
  });
}

export function loadStore(targetStore, storeName) {
  const temp = myStorage.getItem(storeName);
  const item = JSON.parse(temp);
  if (!targetStore) {
    return item;
  }
  if (item) {
    Object.keys(item).forEach(key => {
      targetStore[key] = item[key];
    });
  }
}
