import React from "react";
import { Form, Input, Row, Col, Button, Divider, DatePicker } from "antd";
import styled from "styled-components";

const CustomButton = styled(Button)`
  @media screen and (min-width: 992px) {
    margin-left: 16px;
  }
  margin-top: 16px;
`;
const NotesTab = ({ values }) => {
  const { TextArea } = Input;
  return (
    <>
      <Form.List name="notes" initialValue={values.notes}>
        {(fields, { add, remove }) => (
          <>
            <Form.Item style={{ marginBottom: 10 }}>
              <Button
                onClick={e => {
                  e.preventDefault();
                  add();
                }}
              >
                Not ekle
              </Button>
            </Form.Item>
            {fields.map(field => (
              <div key={"notes-nested-" + field.key}>
                <Col span={24} lg={{ span: 16 }}>
                  <Row
                    style={{ marginBottom: "16px" }}
                    align="bottom"
                    justify="space-between"
                    gutter={[0, 16]}
                  >
                    <Col>
                      {" "}
                      <Form.Item
                        {...field}
                        name={[field.name, "noteCreatedAt"]}
                        fieldKey={[field.fieldKey, "note-date"]}
                        key={"note-nested-" + field.key + "-notedate"}
                        style={{ marginBottom: 0 }}
                      >
                        <DatePicker />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        {...field}
                        name={[field.name, "noteCreatedBy"]}
                        fieldKey={[field.fieldKey, "note-createdby"]}
                        key={"note-nested-" + field.key + "-notecreatedby"}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="Notu alan" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Row align={"bottom"}>
                  <Col span={24} lg={{ span: 16 }}>
                    <Form.Item
                      {...field}
                      name={[field.name, "note"]}
                      fieldKey={[field.fieldKey, "note"]}
                      key={"note-nested-" + field.key + "-"}
                      style={{ marginBottom: 0 }}
                    >
                      <TextArea autoSize={{ minRows: 4 }} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 4 }}>
                    <CustomButton
                      type="dashed"
                      onClick={() => {
                        remove(field.name);
                      }}
                    >
                      Sil
                    </CustomButton>
                  </Col>
                </Row>
                <Divider />
              </div>
            ))}
          </>
        )}
      </Form.List>
    </>
  );
};

export default NotesTab;
