import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

import { useStore } from "../../hooks/useStore";

export const AuthChecker = observer(props => {
  const stores = useStore();
  const history = useHistory();

  return (
    <>
      {stores.loginStore.loggedIn ? (
        <>{props.children}</>
      ) : (
        history.push("/login")
      )}
    </>
  );
});
