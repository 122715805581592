import { observable, action, makeObservable } from "mobx";
import testService from "../services/testService";

class testStore {
  constructor() {
    makeObservable(this);
  }

  @observable counter = 0;

  @action increaseCounter = async () => {
    this.counter++;
  };

  @action decreaseCounter = async () => {
    this.counter--;
  };

  @action getUser = async params => {
    return testService.getUser(params);
  };
}

export default new testStore();
