import { observable, action, makeObservable, runInAction } from "mobx";
import applicantService from "../services/applicantService";
import moment from "moment";
class applicantStore {
  @observable applicantList = { error: "", loading: true, data: [] };
  @observable photoId = "";
  @observable fileList = [];
  constructor() {
    makeObservable(this);
  }
  @action setFileList = fileList => {
    if (fileList) this.fileList = fileList;
    else this.fileList = [];
  };

  setPhotoId = id => {
    this.photoId = id;
  };

  @action getById = async id => {
    try {
      const result = await applicantService.getApplicant(id);
      const applicantData = result.data.data;
      applicantData.education = applicantData.education.map(ed => {
        if (ed) ed.graduationDate = moment(ed.graduationDate, "YYYY-MM-DD");
        return ed;
      });
      applicantData.workExperience = applicantData.workExperience?.map(e => {
        if (e) {
          e.startDate = moment(e.startDate, "YYYY-MM-DD");
          e.endDate = moment(e.endDate, "YYYY-MM-DD");
        }
        return e;
      });
      applicantData.notes = applicantData.notes?.map(e => {
        if (e) {
          e.noteCreatedAt = moment(e.noteCreatedAt, "YYYY-MM-DD");
        }
        return e;
      });
      this.setFileList(applicantData.fileList);
      return applicantData;
    } catch (error) {
      console.log(error);
      return { error: true, message: error };
    }
  };
  @action deleteApplicant = async id => {
    const result = await applicantService.deleteApplicant({ applicantId: id });
  };
  @action editApplicant = async data => {
    const result = await applicantService.editApplicant(data);
  };
  @action fetchPage = async (page, limit, name, surname, tech, allSearch) => {
    try {
      this.applicantList.error = "";
      this.applicantList.loading = true;
      const result = await applicantService.getApplicantList(
        page,
        limit,
        name,
        surname,
        tech,
        allSearch
      );
      this.applicantList.loading = false;
      this.applicantList.data = result.data.data.list;
      return result.data.data.total;
    } catch (error) {
      console.log(error);
      this.applicantList.error = error;
    }
  };
  @action createApplicant = async data => {
    const result = await applicantService.createApplicant(data);
    return result.data;
  };
  @action setCurrentApplicant = async data => {
    await runInAction(() => {
      this.currentApplicant = data;
    });
  };
  @action submitCurrent = option => {
    if (option === "new") this.createApplicant(this.currentApplicant);
    else this.editApplicant(this.currentApplicant);
  };
}

export default new applicantStore();
