import { Upload, Modal } from "antd";
import React from "react";
import stores from "../../../store/stores";
import {
  FileTextOutlined,
  DeleteOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import axios from "axios";
import baseConfig from "../../../_core/config";

const FileUpload = () => {
  const props = {
    name: "file",
    multiple: true,
    listType: "picture-card",
    async customRequest(options) {
      const { onSuccess, onError, file, onProgress } = options;
      const fmData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: event => {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      };
      fmData.append("image", file);
      try {
        const res = await axios.post("file/uploadFile", fmData, config);
        onSuccess(res.data.data);
        stores.applicantStore.setFileList([
          ...stores.applicantStore.fileList,
          {
            fileId: res.data.data.fileId,
            filename: res.data.data.filename,
            parsed: res.data.data.parsed
          }
        ]);
      } catch (err) {
        console.log("Error: ", err);
        onError({ err });
      }
    },
    onDownload(file) {
      const newWindow = window.open(
        baseConfig.getBasePublicUrl() + "file/" + file.response.fileId,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    },
    async onRemove(removedFile) {
      return await onFileRemove(removedFile);
    },
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: true,
      downloadIcon: <DownloadOutlined></DownloadOutlined>,
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined></DeleteOutlined>
    },
    defaultFileList: stores.applicantStore.fileList.map(file => {
      return {
        status: "done",
        name: file.filename,
        uid: file.fileId,
        response: file
      };
    })
  };

  const onFileRemove = removedFile => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: "Silmek istediğinizden emin misiniz?",
        content: removedFile.name + " kalıcı olarak silinecektir.",
        okText: "Sil",
        okType: "danger",
        cancelText: "İptal",
        onOk: async () => {
          let cloneFileList = [...stores.applicantStore.fileList];
          cloneFileList = cloneFileList.filter(
            file => file.fileId !== removedFile.response.fileId
          );
          stores.applicantStore.setFileList(cloneFileList);
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        }
      });
    });
  };

  return (
    <div>
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <FileTextOutlined />
        </p>
        <p className="ant-upload-text">Yüklemek için tıkla veya sürükle</p>
        <p className="ant-upload-hint">Birden fazla dosya yüklenebilir.</p>
      </Upload.Dragger>
    </div>
  );
};

export default FileUpload;
