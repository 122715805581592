import baseRequest from "../_core/baseRequest";
const applicantService = {
  getApplicant: applicantId => baseRequest.get(`/applicant/${applicantId}`),
  deleteApplicant: params => baseRequest.delete(`/applicant`, params),
  createApplicant: params => baseRequest.post(`/applicant`, params),
  editApplicant: params => baseRequest.put(`/applicant`, params),
  getApplicantList: (page, limit, name, surname, tech, allSearch) =>
    baseRequest.get(
      `/applicant/list?page=${page}&limit=${encodeURIComponent(
        limit
      )}&name=${encodeURIComponent(name)}&surname=${encodeURIComponent(
        surname
      )}&tech=${encodeURIComponent(tech)}&allSearch=${encodeURIComponent(
        allSearch
      )}`
    )
};

export default applicantService;
