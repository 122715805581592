import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import stores from "../../store/stores";
import { LogoutOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styled from "styled-components";
export const Logout = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const onLogout = () => {
    stores.loginStore.setUser(null);
    stores.loginStore.setSessionToken(null);
    stores.loginStore.setLoggedIn(false);
    history.push("/login");
  };
  return width < 576 ? (
    <CustomButton onClick={() => onLogout()}>
      <LogoutOutlined />
    </CustomButton>
  ) : (
    <CustomButton onClick={() => onLogout()}>Log out</CustomButton>
  );
};

const CustomButton = styled.div`
  color: black;
  font-size: 18px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
