import axios from "axios";
import { loadStore } from "../store/localStorage";

import config from "./config";

const baseUrl = config.getBasePublicUrl();
axios.defaults.baseURL = baseUrl;

const baseRequest = {
  addHeader: token => {
    let sessionToken = null;
    const loginStore = loadStore(null, "loginStore");
    sessionToken = loginStore && loginStore.sessionToken;
    axios.defaults.headers.common["Authorization"] = token || sessionToken;
  },
  request: (method, path, params, responseType) => {
    return axios({
      method,
      url: path,
      data: params,
      responseType
    })
      .then(result => {
        return result;
      })
      .catch(err => {
        console.log(err);
      });
  },

  get: path => baseRequest.request("GET", path),
  post: (path, params) => baseRequest.request("POST", path, params),
  put: (path, params) => baseRequest.request("PUT", path, params),
  delete: (path, params) => baseRequest.request("DELETE", path, params),
  uploadFile: (path, file, params = {}) => {
    return new Promise(resolve => {
      const url = baseUrl + path;
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      xhr.open("POST", url, true);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      let sessionToken = null;
      const loginStore = loadStore(null, "loginStore");
      sessionToken = loginStore && loginStore.sessionToken;
      xhr.setRequestHeader("Authorization", "" + sessionToken);
      fd.append("datas", JSON.stringify(params));
      fd.append("file", file);
      xhr.send(fd);
    });
  }
};
baseRequest.addHeader();
export default baseRequest;
