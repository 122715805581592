import React, { useEffect, useState } from "react";
import { Table, Input, Row, Pagination, Tag } from "antd";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "../../hooks/useStore";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
const { Column } = Table;

export const ApplicantsTable = observer(() => {
  const stores = useStore();
  const query = new URLSearchParams(useLocation().search);
  const [searchValues, setSearchValues] = useState({
    name: query.get("name") || "",
    surname: query.get("surname") || "",
    tech: query.get("tech") || "",
    search: query.get("search") || ""
  });
  const [refresh, setRefresh] = useState(false); // change state to refresh the list
  const [filtered, setFiltered] = useState([]);
  const [queryJSON, setQueryJSON] = useState({
    page: query.get("page"),
    limit: query.get("limit"),
    name: query.get("name"),
    surname: query.get("surname"),
    tech: query.get("tech"),
    allSearch: query.get("allSearch") || ""
  });
  const [pageControls, setPageControls] = useState({
    page: parseInt(query.get("page")) || 1,
    limit: parseInt(query.get("limit")) || 10,
    total: 0
  });

  const history = useHistory();
  const { width } = useWindowDimensions();
  useEffect(() => {
    //yeni kisi eklendiginde ya da geri gelindiginde photoId sıfırlanır
    stores.applicantStore.setPhotoId("");
  });
  useEffect(() => {
    const queryString =
      "?" +
      Object.keys(queryJSON)
        .reduce(function(filtered, key) {
          if (queryJSON[key])
            filtered.push(
              encodeURIComponent(key) + "=" + encodeURIComponent(queryJSON[key])
            );
          return filtered;
        }, [])
        .join("&");

    history.replace("/" + queryString);
  }, [history, queryJSON]);

  useEffect(() => {
    async function x() {
      const total = await stores.applicantStore.fetchPage(
        pageControls.page,
        pageControls.limit,
        searchValues.name,
        searchValues.surname,
        searchValues.tech,
        queryJSON.allSearch
      );

      setPageControls({ ...pageControls, total: total });
      let clone = toJS(stores.applicantStore.applicantList).data;

      setFiltered(clone);
    }
    x();
  }, [searchValues, refresh, stores.applicantStore, queryJSON]);

  const handlePageChange = async (total, range) => {
    setPageControls({ ...pageControls, page: total, limit: range });
    setQueryJSON({ ...queryJSON, page: total, limit: range });

    setRefresh(!refresh);
  };

  const changeQueryFromNavbar = value => {
    setQueryJSON({ ...queryJSON, allSearch: value });
  };

  return (
    <>
      <Navbar
        defaultValue={queryJSON.allSearch}
        changeQuery={changeQueryFromNavbar}
      ></Navbar>
      <Wrapper>
        <CustomRow justify="center">
          <AddAplicant
            type="primary"
            size={"medium"}
            className="addAplicantButton"
            onClick={() => history.push("/details/new")}
          >
            {width > 768 ? "Yeni Aday Oluştur \u00A0" : ""} <PlusIcon />
          </AddAplicant>
          <Pagination
            showSizeChanger
            total={pageControls.total}
            defaultCurrent={pageControls.page}
            defaultPageSize={pageControls.limit}
            current={pageControls.page}
            onChange={(total, range) => handlePageChange(total, range)}
          ></Pagination>
        </CustomRow>
        <CustomTable
          dataSource={filtered}
          pagination={false}
          rowKey="_id"
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                history.push(`details/${record._id}`);
              } // click row
            };
          }}
          rowClassName="applicant-row"
        >
          <Column
            title={
              <>
                İsim &nbsp;
                <SearchOutlined />
                <CustomInputOnTableHead
                  placeholder="Isim"
                  defaultValue={searchValues.name}
                  onChange={e => {
                    setSearchValues({
                      ...searchValues,
                      name: e.target.value
                    });
                    setQueryJSON({ ...queryJSON, name: e.target.value });
                  }}
                />
              </>
            }
            dataIndex={["basicInfo", "name"]}
            key="firstName"
          />
          <Column
            title={
              <>
                Soyisim &nbsp;
                <SearchOutlined />
                <CustomInputOnTableHead
                  placeholder="Soyisim"
                  defaultValue={searchValues.surname}
                  onChange={e => {
                    setSearchValues({
                      ...searchValues,
                      surname: e.target.value
                    });
                    setQueryJSON({ ...queryJSON, surname: e.target.value });
                  }}
                />
              </>
            }
            dataIndex={["basicInfo", "surname"]}
            key="lastName"
          />

          <Column
            title={
              <>
                Teknolojiler &nbsp;
                <SearchOutlined />
                <CustomInputOnTableHead
                  placeholder="Teknolojiler"
                  defaultValue={searchValues.tech}
                  onChange={e => {
                    setSearchValues({
                      ...searchValues,
                      tech: e.target.value
                    });
                    setQueryJSON({
                      ...queryJSON,
                      tech: e.target.value
                    });
                  }}
                />
              </>
            }
            dataIndex="techStack"
            key="techStack"
            render={tags => (
              <>
                {tags?.map((tag, index) => {
                  if (tag.tech && tag.rate) {
                    let colorMap = {
                      1: "geekblue",
                      2: "green",
                      3: "gold",
                      P: "red"
                    };
                    let color = colorMap[tag.rate];
                    return (
                      <Tag color={color} key={tag.tech + index}>
                        {tag.tech.toUpperCase()}
                      </Tag>
                    );
                  } else return "";
                })}
              </>
            )}
          />
          <Column
            title="Son Görüşme"
            dataIndex="lastNote"
            key="contactDates"
            render={info => <>{info ? info.date : "N/A"}</>}
          />
        </CustomTable>
      </Wrapper>
      <Footer />
    </>
  );
});

const Wrapper = styled.div`
  margin-top: 100px;
  padding-bottom: 60px;
`;

const CustomTable = styled(Table)`
  table {
    border-radius: 3px;
    border-spacing: 16px;
  }
  .ant-table-cell {
    border-bottom: 1px solid rgba(0, 172, 238, 0.93);
    @media (max-width: 768px) {
      padding: 10px;
    }
    @media (max-width: 425px) {
      padding: 5px;
    }
  }
  thead > tr > .ant-table-cell {
    font-size: 18px;
    border: none;
    text-align: center;
    background: none;
  }
  thead > tr > .ant-table-cell > input {
    display: block;
    margin: 6px auto 0;
    background: #fffefe;
    border-radius: 10px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  }
  .applicant-row {
    cursor: pointer;
  }
`;

const CustomRow = styled(Row)`
  padding: 1rem;
  align-items: center;
`;
const AddAplicant = styled.a`
  position: absolute;
  display: flex;
  left: 0;
  font-size: 18px;
  margin-left: 10px;

  color: black;
`;

const PlusIcon = styled(PlusCircleOutlined)`
  font-size: 32px;
`;
const CustomInputOnTableHead = styled(Input)`
  width: 60%;
  margin-left: 20px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 5px;
  }
`;
