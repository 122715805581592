import { React } from "react";
import { Logout } from "../logoutButton/Logout";
import styled from "styled-components";
import logo from "../../assets/cvpool-logo.png";
import smallLogo from "../../assets/cvpool-small.png";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
const HeaderWrapper = styled.div`
  background-color: #00acee;
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px 33px 0 41px;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 576px) {
    padding: 5px 16px 0 16px;
  }
`;
const Logo = styled.img`
  cursor: pointer;
  max-height: 90px;
  min-height: 0;
  min-width: 0;
`;
const SearchInput = styled(Input.Search)`
  width: 50%;
  .ant-input {
    border-radius: 10px 0 0 10px;
  }

  .ant-input-group-addon {
    background: transparent;
  }
  .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 10px 10px 0;
  }
`;
const Navbar = ({ defaultValue, changeQuery, homePage = true }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const handleSearch = value => {
    if (homePage) changeQuery(value);
    else
      history.push({
        pathname: "/",
        search: `?allSearch=${value}`
      });
  };
  return (
    <HeaderWrapper>
      <Logo
        src={width > 992 ? logo : smallLogo}
        onClick={() => {
          history.push("/");
        }}
      ></Logo>

      <SearchInput
        size="large"
        placeholder="Ara.."
        defaultValue={defaultValue || ""}
        onSearch={handleSearch}
      />

      <Logout></Logout>
    </HeaderWrapper>
  );
};

export default Navbar;
