import { Form, Input, Row, Col, Button, DatePicker, Divider } from "antd";
import styled from "styled-components";

const EducationTab = ({ values }) => {
  return (
    <div>
      <Form.List name="education" initialValue={values?.education}>
        {(fields, { add, remove }) => (
          <>
            <Form.Item>
              <Button
                onClick={e => {
                  e.preventDefault();
                  add();
                }}
              >
                Okul Ekle
              </Button>
            </Form.Item>
            {fields.map(field => (
              <div key={"education-nested-" + field.key}>
                <Row gutter={[16, 0]} align="middle">
                  <Col span={24} lg={{ span: 8 }}>
                    <Form.Item
                      {...field}
                      label="Okul"
                      name={[field.name, "school"]}
                      fieldKey={[field.fieldKey, "school"]}
                      key={"education-nested-" + field.key + "-school"}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 8 }}>
                    <Form.Item
                      {...field}
                      label="Bölüm"
                      name={[field.name, "major"]}
                      fieldKey={[field.fieldKey, "major"]}
                      key={"education-nested-" + field.key + "-major"}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={20} lg={{ span: 6 }}>
                    <Form.Item
                      {...field}
                      label="Mezuniyet Tarihi"
                      name={[field.name, "graduationDate"]}
                      fieldKey={[field.fieldKey, "graduationDate"]}
                      key={"education-nested-" + field.key + "-graduationDate"}
                    >
                      <FullWidthDatePicker
                        format={"DD/MM/YYYY"}
                        placeholder={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4} lg={{ span: 2 }}>
                    <CustomButton
                      type="dashed"
                      onClick={() => {
                        remove(field.name);
                      }}
                      block
                    >
                      Sil
                    </CustomButton>
                  </Col>
                </Row>

                <Divider></Divider>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};
const CustomButton = styled(Button)`
  margin-top: 8px;
`;
const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;
export default EducationTab;
