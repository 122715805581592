import React, { useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import { useStore } from "../../hooks/useStore";

export const Test = observer(({ passedUrlValue }) => {
  const stores = useStore();
  const history = useHistory();
  useEffect(() => {
    const user = stores.testStore.getUser({
      userId: "5bbcbc9bd33a630fa59194ad"
    });
    console.log("user", user);
  });
  return (
    <Container>
      <div>
        <div> Test Page </div>
        {passedUrlValue && <div>PassedValue: {passedUrlValue}</div>}
        <div>
          <Button onClick={stores.testStore.increaseCounter}>increase</Button>
        </div>
        <div>
          <Button onClick={() => stores.testStore.decreaseCounter()}>
            decrease
          </Button>
        </div>
        <div>Counter: {stores.testStore.counter}</div>
        <div>
          <LoginButton onClick={() => history.push("/login")}>
            Go to login page
          </LoginButton>
        </div>
      </div>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
`;

const LoginButton = styled.button`
  margin-top: 50px;
`;
