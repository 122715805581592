import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Theme } from "../components/theme/Theme";
import { Login } from "../components/loginPage/Login";
import { Test } from "../components/testPage/Test";
import { ApplicantsTable } from "../components/tablePage/ApplicantsTable";

import { Details } from "../components/detailsPage/Details";
import { AuthChecker } from "../components/authChecker/authChecker";
const Routes = observer(() => {
  return (
    <BrowserRouter>
      <Theme>
        <Switch>
          <Route exact path="/login" render={props => <Login {...props} />} />
          <Route
            exact
            path="/"
            render={props => {
              return (
                <AuthChecker {...props}>
                  <ApplicantsTable />
                </AuthChecker>
              );
            }}
          />
          <Route
            exact
            path="/details/:id"
            render={props => (
              <AuthChecker {...props}>
                <Details applicantId={props.match.params.id} {...props} />
              </AuthChecker>
            )}
          />
          <Route exact path="*" render={props => <Test {...props} />} />
        </Switch>
      </Theme>
    </BrowserRouter>
  );
});

export default Routes;
