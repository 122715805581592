import React from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "antd";

export const Theme = observer(props => {
  return (
    <Row justify="center">
      <Col span={20}>{props.children}</Col>
    </Row>
  );
});
