import React from "react";
import "antd/dist/antd.css";
import { ConfigProvider } from "antd";
import trTr from "antd/lib/locale/tr_TR";
import { StoreProvider } from "./store/appContext";
import Routes from "./routes/index";
import stores from "./store/stores";

function App() {
  return (
    <StoreProvider value={stores}>
      <ConfigProvider locale={trTr}>
        <Routes />
      </ConfigProvider>
    </StoreProvider>
  );
}

export default App;
