import {
  Form,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  Space,
  Divider
} from "antd";
import styled from "styled-components";
const CustomButton = styled(Button)`
  margin-top: 8px;
`;
const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;
const ExperienceTab = ({ values }) => {
  return (
    <div>
      <Form.List name="workExperience" initialValue={values.workExperience}>
        {(fields, { add, remove }) => (
          <>
            <Form.Item>
              <Button
                onClick={e => {
                  e.preventDefault();
                  add();
                }}
              >
                Yeni Deneyim Ekle
              </Button>
            </Form.Item>
            {fields.map(field => (
              <div key={"workExperience-nested-" + field.key}>
                <Row gutter={12}>
                  <Col span={24} lg={{ span: 8 }}>
                    <Form.Item
                      {...field}
                      label="Pozisyon"
                      name={[field.name, "jobTitle"]}
                      fieldKey={[field.fieldKey, "jobTitle"]}
                      key={"workExperience-nested-" + field.key + "-jobTitle"}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 8 }}>
                    <Form.Item
                      {...field}
                      label="Başlangıç"
                      name={[field.name, "startDate"]}
                      fieldKey={[field.fieldKey, "startDate"]}
                      key={"workExperience-nested-" + field.key + "-startDate"}
                    >
                      <FullWidthDatePicker
                        format={"DD/MM/YYYY"}
                        placeholder={"DD/MM/YYYY"}
                      ></FullWidthDatePicker>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 8 }}>
                    <Form.Item
                      {...field}
                      label="Bitiş"
                      name={[field.name, "endDate"]}
                      fieldKey={[field.fieldKey, "endDate"]}
                      key={"workExperience-nested-" + field.key + "-endDate"}
                    >
                      <FullWidthDatePicker
                        format={"DD/MM/YYYY"}
                        placeholder={"DD/MM/YYYY"}
                      ></FullWidthDatePicker>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  {...field}
                  label="Açıklama"
                  name={[field.name, "description"]}
                  fieldKey={[field.fieldKey, "description"]}
                  key={"workExperience-nested-" + field.key + "-description"}
                >
                  <Input.TextArea />
                </Form.Item>
                <Space>
                  <CustomButton
                    type="dashed"
                    onClick={() => {
                      remove(field.name);
                    }}
                    block
                  >
                    Sil
                  </CustomButton>
                </Space>

                <Divider></Divider>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ExperienceTab;
