import styled from "styled-components";
import logo from "../../assets/logo.png";
import { UpCircleOutlined } from "@ant-design/icons";
const Footer = () => {
  return (
    <Wrapper>
      <Logo
        src={logo}
        onClick={() => window.open("https://codeventure.com.tr/", "blank")}
      />
      <UpIcon onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  padding-bottom: 8px;
  width: 83.33%;
  background: white;
`;
const Logo = styled.img`
  cursor: pointer;
  height: 24px;
`;
const UpIcon = styled(UpCircleOutlined)`
  cursor: pointer;
  margin-left: auto;
  font-size: 48px;
  transition: color 0.3s;
  :hover {
    color: #00acee;
  }
`;
export default Footer;
