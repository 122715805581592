import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Button,
  Select,
  InputNumber
} from "antd";
import moment from "moment";
import styled from "styled-components";
import { ImageUpload } from "./ImageUpload";

const CustomButton = styled(Button)`
  margin-top: 8px;
`;
const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;
const techArray = [
  ".NET",
  ".NET Core",
  "AngularJS",
  "Django",
  "Laravel",
  "MongoDB",
  "MySQL",
  "PostgresSQL",
  "React",
  "VueJS"
];
const BasicInfoTab = ({ values, errors, photoUrl }) => {
  return (
    <Col>
      <Row>
        <ImageUpload photoUrl={photoUrl} />
      </Row>
      <Row gutter={12}>
        <Col span={24} lg={{ span: 12 }}>
          <Form.Item
            label="İsim"
            name={["basicInfo", "name"]}
            initialValue={values.basicInfo?.name}
            validateStatus={errors.basicInfo?.name ? "error" : ""}
            help={errors.basicInfo?.name}
            required
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Soyisim "
            name={["basicInfo", "surname"]}
            initialValue={values.basicInfo?.surname}
            validateStatus={errors.basicInfo?.surname ? "error" : ""}
            help={errors.basicInfo?.surname}
            required
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Email "
            name={["basicInfo", "email"]}
            initialValue={values.basicInfo?.email}
            validateStatus={errors.basicInfo?.email ? "error" : ""}
            help={errors.basicInfo?.email}
            required
          >
            <Input></Input>
          </Form.Item>

          <Form.List name="techStack" initialValue={values.techStack}>
            {(fields, { add, remove }) => (
              <>
                <Form.Item label="Tech Stack" help={""}>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      add();
                    }}
                  >
                    Teknoloji Ekle
                  </Button>
                </Form.Item>
                {fields.map(field => (
                  <div key={"tech-stack-nested-" + field.key}>
                    <Row gutter={[16, 0]} align="middle">
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          label="Teknoloji"
                          name={[field.name, "tech"]}
                          fieldKey={[field.fieldKey, "tech"]}
                          key={"tech-stack-" + field.key + "-tech"}
                        >
                          <Select showSearch>
                            {techArray.map((tech, index) => (
                              <Select.Option
                                value={tech}
                                key={`tech-option-${index}`}
                              >
                                {tech}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          label="Seviye"
                          name={[field.name, "rate"]}
                          fieldKey={[field.fieldKey, "rate"]}
                          key={"tech-stack-" + field.key + "-rate"}
                        >
                          <Select>
                            <Select.Option value={1}>
                              Basit Seviye
                            </Select.Option>
                            <Select.Option value={2}>Orta Seviye</Select.Option>
                            <Select.Option value={3}>
                              İleri Seviye
                            </Select.Option>
                            <Select.Option value="P">
                              Birincil Teknoloji
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <CustomButton
                          type="dashed"
                          onClick={() => {
                            remove(field.name);
                          }}
                          block
                        >
                          Sil
                        </CustomButton>
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Form.Item
            label="Telefon"
            name={["basicInfo", "phone"]}
            initialValue={values.basicInfo?.phone}
            validateStatus={errors.basicInfo?.phone ? "error" : ""}
            help={errors.basicInfo?.phone}
            required
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="Doğum Tarihi"
            name={["basicInfo", "birthDate"]}
            validateStatus={errors.basicInfo?.birthDate ? "error" : ""}
            help={errors.basicInfo?.birthDate}
            initialValue={
              values.basicInfo?.birthDate
                ? moment(values.basicInfo?.birthDate, "YYYY-MM-DD")
                : ""
            }
          >
            <FullWidthDatePicker
              format={"DD/MM/YYYY"}
              placeholder={"DD/MM/YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Sigorta Durumu"
            name={["basicInfo", "insurance"]}
            initialValue={values.basicInfo?.insurance}
          >
            <Select>
              <Select.Option value="Aktif">Aktif</Select.Option>
              <Select.Option value="Pasif">Pasif</Select.Option>
            </Select>
          </Form.Item>
          <Form.List
            name="salaryExpectation"
            initialValue={values.salaryExpectation}
          >
            {(fields, { add, remove }) => (
              <>
                <Form.Item
                  label="Maaş Beklentisi"
                  validateStatus={errors.salaryExpectation ? "error" : ""}
                  help={errors.salaryExpectation ? "Zorunlu alanlar eksik" : ""}
                  required
                >
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      add();
                    }}
                  >
                    Maaş Beklentisi Ekle
                  </Button>
                </Form.Item>
                {fields.map(field => (
                  <div key={"salary-expectation-nested-" + field.key}>
                    <Row gutter={[16, 0]} align="middle">
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          label="Pozisyon "
                          name={[field.name, "workType"]}
                          fieldKey={[field.fieldKey, "workType"]}
                          key={"salary-expectation-" + field.key + "-workType"}
                          required
                        >
                          <Select>
                            <Select.Option value="Part-Time">
                              Part-Time
                            </Select.Option>
                            <Select.Option value="Full-Time">
                              Full-Time
                            </Select.Option>
                            <Select.Option value="Freelance">
                              Freelance
                            </Select.Option>
                            <Select.Option value="Intern">Intern</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          {...field}
                          label="Maaş Beklentisi"
                          name={[field.name, "salary"]}
                          fieldKey={[field.fieldKey, "salary"]}
                          key={"salary-expectation-" + field.key + "-salary"}
                          required
                        >
                          <InputNumber min={0} />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <CustomButton
                          type="dashed"
                          onClick={() => {
                            remove(field.name);
                          }}
                          block
                        >
                          Sil
                        </CustomButton>
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </Col>
  );
};

export default BasicInfoTab;
