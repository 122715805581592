import { observable, action, makeObservable } from "mobx";
import loginService from "../services/loginService";
const localStorage = require("../store/localStorage");

class loginStore {
  @observable user = {};
  @observable sessionToken = "";
  @observable loggedIn = false;
  constructor() {
    makeObservable(this);
    localStorage.loadStore(this, "loginStore");
    localStorage.autoSave(this, "loginStore");
  }

  setSessionToken = sessionToken => {
    this.sessionToken = sessionToken;
  };
  setUser = user => {
    this.user = user;
  };
  setLoggedIn = login => {
    this.loggedIn = login;
  };

  @action loginRequest = async params => {
    return loginService.loginRequest(params);
  };
  @action addHeader = () => {
    return loginService.addHeader();
  };
}

export default new loginStore();
