import { Form, Row, Col, Button, Divider, Select } from "antd";
import styled from "styled-components";
const CustomButton = styled(Button)`
  margin-top: 8px;
`;
const languageArray = [
  "Arabic",
  "English",
  "French",
  "German",
  "Spanish",
  "Turkish"
];

const LanguagesTab = ({ values }) => {
  return (
    <div>
      <Form.List name="languages" initialValue={values.languages}>
        {(fields, { add, remove }) => (
          <>
            <Form.Item>
              <Button
                onClick={e => {
                  e.preventDefault();
                  add();
                }}
              >
                Yabancı Dil Ekle
              </Button>
            </Form.Item>
            {fields.map(field => (
              <div key={"languages-nested-" + field.key}>
                <Row gutter={[16, 0]} align="middle">
                  <Col span={16}>
                    <Form.Item
                      {...field}
                      label="Yabancı Dil"
                      name={[field.name, "languages"]}
                      fieldKey={[field.fieldKey, "languages"]}
                      key={"languages-" + field.key + "-"}
                    >
                      <Select showSearch>
                        {languageArray.map((language, index) => (
                          <Select.Option
                            value={language}
                            key={`language-option-${index}`}
                          >
                            {language}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <CustomButton
                      type="dashed"
                      onClick={() => {
                        remove(field.name);
                      }}
                      block
                    >
                      Sil
                    </CustomButton>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={24} lg={{ span: 8 }}>
                    <Form.Item
                      {...field}
                      label="Okuma"
                      name={[field.name, "reading"]}
                      fieldKey={[field.fieldKey, "reading"]}
                      key={"languages-" + field.key + "-reading"}
                    >
                      <Select showSearch>
                        {[...Array(10).keys()].map((number, index) => (
                          <Select.Option
                            value={number + 1}
                            key={`reading-option-${number + 1}`}
                          >
                            {number + 1}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 8 }}>
                    <Form.Item
                      {...field}
                      label="Yazma"
                      name={[field.name, "writing"]}
                      fieldKey={[field.fieldKey, "writing"]}
                      key={"languages-" + field.key + "-writing"}
                    >
                      <Select showSearch>
                        {[...Array(10).keys()].map((number, index) => (
                          <Select.Option
                            value={number + 1}
                            key={`writing-option-${number + 1}`}
                          >
                            {number + 1}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 8 }}>
                    <Form.Item
                      {...field}
                      label="Konuşma"
                      name={[field.name, "speaking"]}
                      fieldKey={[field.fieldKey, "speaking"]}
                      key={"languages-" + field.key + "-speaking"}
                    >
                      <Select showSearch>
                        {[...Array(10).keys()].map((number, index) => (
                          <Select.Option
                            value={number + 1}
                            key={`speaking-option-${number + 1}`}
                          >
                            {number + 1}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default LanguagesTab;
