import testStore from "./testStore";
import loginStore from "./loginStore";
import applicantStore from "./applicantStore";
const stores = {
  testStore,
  loginStore,
  applicantStore
};

export default stores;
