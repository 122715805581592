import { Form, Input, Col, Row, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
const CustomButton = styled(Button)`
  margin-bottom: 24px;
`;
const RoundAddonInput = styled(Input)`
  .ant-input-group-addon {
    border-radius: 10px 0 0 10px;
  }
`;
const Links = props => {
  return (
    <div>
      <Form.List name="link" initialValue={props.values.link}>
        {(fields, { add, remove }) => (
          <>
            <Form.Item>
              <Button
                onClick={e => {
                  e.preventDefault();
                  add();
                }}
              >
                Link ekle
              </Button>
            </Form.Item>
            {fields.map(field => (
              <div key={"link-nested-" + field.key}>
                <Row gutter={[16, 0]} align="middle">
                  <Col span={12}>
                    <Form.Item
                      {...field}
                      name={[field.name, "link"]}
                      fieldKey={[field.fieldKey, "link"]}
                      initialValue={props.aplicantData?.links}
                      key={"link-nested-" + field.key + "-"}
                    >
                      <RoundAddonInput addonBefore="https://" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <CustomButton
                      onClick={() => {
                        window.open(
                          "https://" + props.values.link[field.fieldKey].link
                        );
                      }}
                    >
                      <SearchOutlined />
                    </CustomButton>
                  </Col>
                  <Col>
                    <CustomButton
                      type="dashed"
                      onClick={() => {
                        remove(field.name);
                      }}
                    >
                      Sil
                    </CustomButton>
                  </Col>
                </Row>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default Links;
