import React, { useState, useEffect } from "react";
import { Upload } from "antd";
import styled from "styled-components";
import stores from "../../../store/stores";
import axios from "axios";

const Wrapper = styled.div`
  margin-bottom: 15px;
`;

const CustomUpload = styled(Upload)`
  width: 200px;
  height: 200px;
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
    border: 2px solid rgba(0, 172, 238, 0.5);
    border-radius: 10px;
    :hover {
      border: 2px solid rgba(0, 172, 238, 0.8);
    }
  }

  .ant-upload-list-item {
    width: 200px;
    height: 200px;
    border: 2px solid rgba(0, 172, 238, 0.5);
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    &.ant-upload-list-item-error {
      border-color: #ff4d4f !important;
    }
  }
`;
export const ImageUpload = ({ photoUrl }) => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  useEffect(() => {
    if (photoUrl)
      setDefaultFileList([
        {
          uid: "-1",
          status: "done",
          url: photoUrl
        }
      ]);
  }, [photoUrl]);
  const handleUpload = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const uploadConfig = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: event => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    fmData.append("image", file);
    try {
      const res = await axios.post("file/uploadimage", fmData, uploadConfig);
      onSuccess("Ok");
      stores.applicantStore.setPhotoId(res.data.data.imageId);
    } catch (err) {
      console.log("Error: ", err);
      onError({ err });
    }
  };

  const onChange = ({ file, fileList, event }) => {
    //Using Hooks to update the state to the current filelist
    setDefaultFileList(fileList);
    //filelist - [{uid: "-1",url:'Some url to image'}]
  };
  const onRemove = removedFile => {
    stores.applicantStore.setPhotoId("-1");
  };
  return (
    <Wrapper>
      <CustomUpload
        accept="image/*"
        customRequest={handleUpload}
        onChange={onChange}
        onRemove={onRemove}
        listType="picture-card"
        fileList={defaultFileList}
      >
        {defaultFileList.length >= 1 ? null : (
          <div>
            <p>Fotoğraf</p> <p>Yükle</p>
          </div>
        )}
      </CustomUpload>
    </Wrapper>
  );
};
